$transition: all 0.9s ease;

:root {
    font-size: 16px;
}

* {
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 0.495rem;
    }

    &::-webkit-scrollbar-track {
        background: rgb(56, 56, 56);
    }

    &::-webkit-scrollbar-thumb {
        background: rgb(157, 252, 255);
        border-radius: 100vw;

        &:hover {
            background: rgba(157, 252, 255, 0.8);
        }
    }
}

button,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.light-mode-scrollbar {
    scroll-behavior: smooth;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 0.495rem;
    }

    &::-webkit-scrollbar-track {
        background: rgb(56, 56, 56);
    }

    &::-webkit-scrollbar-thumb {
        background: rgb(117, 170, 135);
        border-radius: 100vw;

        &:hover {
            background: rgba(117, 170, 135, 0.8);
        }
    }
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.section-header {
    align-self: center;
    margin-bottom: 1.5rem;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    letter-spacing: 0.125rem;
    transition: $transition;
    -moz-transition: $transition;
    -webkit-transition: $transition;
    -ms-transition: $transition;
    font-size: clamp(1.715em, 7vw, 2.125em);

    /** Media Queries */
    // /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        font-size: clamp(1.925em, 12vw, 2.825em);
    }

    // /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        font-size: clamp(1.825em, 12vw, 3.325em);
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        font-size: clamp(2.925em, 12vw, 4.225em);
    }
}

.section-content {
    height: 100vh;
    font-size: 21px;
    font-family: "Libre Franklin", sans-serif;
    letter-spacing: 0.025rem;
    transition: $transition;
    -moz-transition: $transition;
    -webkit-transition: $transition;
    -ms-transition: $transition;
}

.fade-up-animation {
    opacity: 1 !important;
    animation: 1200ms 0ms 1 linear fade-up;
    -webkit-animation: 1200ms 0ms 1 linear fade-up;
    -moz-animation: 1200ms 0ms 1 linear fade-up;
}

.opacity-1 {
    opacity: 1 !important;
}

// Animations
@keyframes fade-up {
    from {
        transform: translateY(10px);
        -webkit-transform: translateY(10px);
        -ms-transform: translateY(10px);
    }

    to {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
    }
}
