.menu {
    font-size: 1.125rem;
    width: clamp(13rem, 10%, 15rem);
    height: 100vh;
    position: fixed;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Libre Franklin", sans-serif;

    .menu-logo {
        animation: 1200ms ease-out 0ms 1 menu-fade-in-from-left;
    }

    ul {
        padding: 0;
        margin: 0;
        width: 100%;
        margin-top: 1.325rem;

        li {
            list-style: none;
            font-size: 1.425rem;
            margin-top: 1.925rem;
            animation: 1200ms ease-out 0ms 1 menu-fade-in-from-left;

            &:nth-child(4) {
                margin-bottom: 4.325rem;
            }

            &::after {
                content: "";
                animation: none;
                display: block;
                height: 1px;
                margin-top: 1.925rem;
                background: rgb(170, 170, 170);
            }

            a {
                text-decoration: none;
                transition: 0.9s ease;
                margin-left: clamp(2.7rem, 3vw, 3.4rem);

                &.light-hover {
                    &:hover {
                        transition: none;
                        color: rgb(117, 170, 135);
                    }
                }

                &.dark-hover {
                    &:hover {
                        transition: none;
                        color: rgb(104, 215, 231);
                    }
                }
            }
        }
    }

    .social {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin-top: 0.975rem;
        margin-bottom: 3.2rem;
        animation: 1200ms ease-out 0ms 1 menu-fade-in-from-left;

        a {
            text-decoration: none;
        }
    }

    .menu-mode-change {
        animation: 1200ms ease-out 0ms 1 menu-fade-in-from-left;
    }
}

/* Media Queries */
/* Really Large devices (1900px+) */
@media (max-width: 118.75em) {
    .menu {
        font-size: 1.155rem;

        ul {
            li {
                font-size: 1.455rem;
            }
        }
    }
}

/* Large devices (laptops/desktops, 991px and up) */
@media (max-width: 991px) {
    .menu {
        display: none;
    }
}

/* For landscape modes up to 1200px */
@media screen and (max-width: 1200px) and (orientation: landscape) {
    .menu {
        display: none;
    }
}

/* Animations */
@keyframes menu-fade-in-from-left {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
