.moon-svg {
    cursor: pointer;
    width: 2.325em;
    height: 2.325em;

    path {
        transition: all 0.9s ease;
    }

    &:hover {
        transform: scale(1.1);

        path {
            transition: none;
            fill: rgb(117, 170, 135);
        }

        @media (max-width: 992px) {
            transform: none;
        }
    }
}
