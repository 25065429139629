.contact-content {
    h2 {
        opacity: 0;
        margin-top: auto;
        margin-bottom: 3rem;
    }

    .body {
        opacity: 0;
        font-size: clamp(0.715em, 4vw, 0.925em);
        text-align: center;
        padding: 0 clamp(1rem, 7vw, 3rem);
        transition: all 0.9s ease;
    }

    a {
        cursor: pointer;
        margin-top: 6rem;
        margin-bottom: auto;
        padding: clamp(0.925rem, 5vw, 1.2rem) clamp(2.3rem, 6vw, 5rem);
        color: rgb(0, 0, 0);
        border: 2px solid rgb(117, 170, 135);
        font-size: clamp(0.615em, 4vw, 0.895em);
        font-weight: 100;
        background-color: rgb(255, 255, 255);
        transition: all 0.9s ease;
        text-decoration: none;
        opacity: 0;

        &:hover {
            transition: none;
            background-color: rgba(226, 226, 226, 0.2);
        }

        &:not([class*="light-mode"]) {
            color: rgb(157, 252, 255);
            border: 2px solid rgb(157, 252, 255);
            background-color: rgb(0, 0, 0);

            &:hover {
                transition: none;
                background-color: rgba(68, 67, 67, 0.3);
            }
        }
    }

    .footer {
        font-size: clamp(0.475em, 3vw, 0.55em);
        line-height: 1.525rem;
        margin-bottom: 1.325rem;

        p {
            opacity: 0;
            transition: all 0.9s ease;
        }
    }
}

/* Media Queries */
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .contact-content {
        .body {
            padding: 0 clamp(3rem, 12vw, 7rem);
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .contact-content {
        .body {
            padding: 0 clamp(3rem, 12vw, 4rem);
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .contact-content {
        .body {
            max-width: 60ch;
            padding: 0 clamp(5rem, 12vw, 12rem);
        }
    }
}
