$light-green: rgb(117, 170, 135);
$cyan: rgb(104, 215, 231);

.logo {
    margin-bottom: 2.5rem;
    background-color: transparent;
    width: 4.625em;
    height: 4.625em;

    * {
        transition: 0.7s ease;
    }

    #logo-border {
        fill: rgb(255, 255, 255);
        stroke: $light-green;
    }

    #logo-letter {
        fill: $light-green;
    }

    #logo-top-line,
    #logo-bottom-line {
        stroke: $light-green;
    }

    &:hover {
        transition: none;

        #logo-top-line {
            animation-duration: 0.5s;
            animation-name: slide-in;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
        }

        #logo-bottom-line {
            animation-delay: 0.1s;
            animation-duration: 0.5s;
            animation-name: slide-in;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
        }
    }

    &:not([class*="light-mode"]) {
        #logo-border {
            fill: rgb(0, 0, 0);
            stroke: $cyan;
        }

        #logo-letter {
            fill: $cyan;
        }

        #logo-top-line,
        #logo-bottom-line {
            stroke: $cyan;
        }
    }
}

/* Media Queries */
/* Large devices (laptops/desktops, 991px and lower) */
@media (max-width: 991px) {
    .logo {
        width: 4.35rem;
        height: 4.35rem;

        #logo-top-line,
        #logo-bottom-line {
            opacity: 1;
        }

        &:hover {
            #logo-top-line,
            #logo-bottom-line {
                animation: none;
            }
        }
    }
}

/* For landscape modes up to 1200px */
@media screen and (max-width: 1200px) and (orientation: landscape) {
    .logo {
        #logo-top-line,
        #logo-bottom-line {
            opacity: 1;
        }

        &:hover {
            #logo-top-line,
            #logo-bottom-line {
                animation: none;
            }
        }
    }
}

@keyframes slide-in {
    from {
        transform: translateX(-20%);
    }

    75% {
        opacity: 0.75;
    }

    to {
        opacity: 1;
        transform: translateX(0%);
    }
}
