$transition: all 0.9s ease;

.about-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 1rem;

    .about-details {
        p {
            opacity: 0;
            transition: $transition;
            -moz-transition: $transition;
            -webkit-transition: $transition;
            -ms-transition: $transition;
            font-size: clamp(0.425em, 7vw, 0.635em);
            margin-bottom: 1.4rem;
        }
    }

    .about-daily {
        width: 100%;

        h3 {
            font-family: "Alegreya Sans", sans-serif;
            font-weight: 500;
            letter-spacing: 0.075rem;
            transition: $transition;
            -moz-transition: $transition;
            -webkit-transition: $transition;
            -ms-transition: $transition;
            opacity: 0;
            font-size: clamp(0.375em, 7vw, 0.715em);
        }

        ul {
            display: flex;
            flex-direction: row;
            margin-top: 0.5rem;
            font-size: clamp(0.515em, 3vw, 0.775em);
            justify-content: flex-start;
            justify-content: space-between;

            div {
                li {
                    line-height: 1.5rem;
                    list-style: none;
                    padding: 0.225rem;
                    transition: $transition;
                    -moz-transition: $transition;
                    -webkit-transition: $transition;
                    -ms-transition: $transition;
                    opacity: 0;

                    &:nth-child(odd)::before {
                        content: " \27A2";
                        display: inline-block;
                        margin-right: 0.425rem;
                        font-size: 8px;
                    }

                    &:nth-child(even)::before {
                        content: " \27A3";
                        display: inline-block;
                        margin-right: 0.425rem;
                        font-size: 8px;
                    }
                }
            }
        }
    }
}

/* Media Queries */
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .about-content {
        padding: 0 2rem;
        height: 100vh;

        .about-details {
            p {
                font-size: clamp(0.525em, 7vw, 0.875em);
                margin-bottom: 1.2rem;
            }
        }

        .about-daily {
            margin-top: 1.2rem;

            h3 {
                font-size: clamp(0.525em, 7vw, 1.115em);
            }

            ul {
                margin-top: 1rem;
                font-size: clamp(0.515em, 3vw, 0.675em);
                justify-content: flex-start;

                div {
                    width: 100%;

                    li {
                        &:nth-child(odd)::before {
                            font-size: 10px;
                        }

                        &:nth-child(even)::before {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .about-content {
        .about-details {
            p {
                font-size: clamp(0.875em, 7vw, 0.925em);
                margin-bottom: 1.4rem;
            }
        }

        .about-daily {
            margin-top: 2.8rem;

            h3 {
                font-size: clamp(0.825em, 7vw, 1.225em);
            }

            ul {
                font-size: clamp(0.515em, 3vw, 0.875em);
                justify-content: flex-start;

                div {
                    li {
                        padding: 0.65rem;

                        &:nth-child(odd)::before {
                            font-size: 13px;
                        }

                        &:nth-child(even)::before {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .about-content {
        padding: 0 5rem;

        .about-details {
            margin-top: 2rem;
            p {
                font-size: clamp(0.975em, 7vw, 1.015em);
                margin-bottom: 3rem;
            }
        }

        .about-daily {
            margin-top: 1rem;

            h3 {
                font-size: clamp(1.125em, 7vw, 1.425em);
            }

            ul {
                font-size: clamp(0.775em, 3vw, 0.815em);

                div {
                    li {
                        padding: 1.15rem;

                        &:nth-child(odd)::before {
                            font-size: 15px;
                        }

                        &:nth-child(even)::before {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}

/** Landscape media query **/
@media (orientation: landscape) {
    .about-content {
        .about-details {
            p {
                font-size: clamp(0.775em, 7vw, 0.825em);
                margin-bottom: 1rem;
            }
        }

        .about-daily {
            margin-top: 1rem;

            h3 {
                font-size: clamp(0.825em, 7vw, 1.05em);
            }

            ul {
                font-size: clamp(0.515em, 3vw, 0.775em);

                div {
                    li {
                        padding: 0.65rem;

                        &:nth-child(odd)::before {
                            font-size: 11px;
                        }

                        &:nth-child(even)::before {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }
}

/* Huge Devices */
@media only screen and (min-width: 1500px) {
    .about-content {
        padding: 0 15rem;

        .about-details {
            p {
                font-size: clamp(0.875em, 13vw, 1.025em);
                margin-bottom: 2rem;
            }
        }

        .about-daily {
            margin-top: 1rem;

            h3 {
                font-size: clamp(0.825em, 7vw, 1.215em);
            }

            ul {
                font-size: clamp(0.515em, 3vw, 0.875em);

                div {
                    li {
                        padding: 0.65rem;

                        &:nth-child(odd)::before {
                            font-size: 16px;
                        }

                        &:nth-child(even)::before {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

// Animations
@keyframes about-fade-up {
    from {
        transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -ms-transform: translateY(200px);
    }

    to {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
    }
}
