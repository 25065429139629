.email-svg {
    cursor: pointer;
    transition: transform 250ms;
    width: 1.725em;
    height: 1.725em;

    polygon,
    path {
        transition: 0.9s ease;
        fill: rgb(0, 0, 0);
    }

    &:hover {
        transform: translateY(-5px);

        polygon,
        path {
            transition: none;
            fill: rgb(117, 170, 135);
        }
    }

    &:not([class*="light-mode"]) {
        polygon,
        path {
            transition: 0.9s ease;
            fill: rgb(255, 255, 255);
        }

        &:hover {
            polygon,
            path {
                transition: none;
                fill: rgb(104, 215, 231);
            }
        }
    }
}
