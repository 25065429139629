.main-page-container {
    transition: all 0.9s ease;
    width: 100%;
    cursor: default;

    .main-page {
        margin-left: clamp(13rem, 90%, 15rem);
    }

    &:not([class*="light-background"]) {
        background: rgb(0, 0, 0);
    }
}

.light-background {
    background: rgb(255, 255, 255);
}

/* Media Queries */
/* Up to Large devices (laptops/desktops, 991px and lower) */
@media (max-width: 991px) {
    .main-page-container {
        .main-page {
            margin: 0;
        }
    }
}

/* For landscape modes up to 1200px */
@media screen and (max-width: 1200px) and (orientation: landscape) {
    .main-page-container {
        .main-page {
            margin: 0;
        }
    }
}
