.cyan-text {
    color: rgb(104, 215, 231);
}

.light-green-text {
    color: rgb(117, 170, 135);
}

.black-text {
    color: rgb(36, 36, 36);
}

.white-text {
    color: rgb(255, 255, 255);
}
