.project-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    flex-direction: column;
    padding: 0 1.225rem;

    h2 {
        opacity: 0;
    }

    .card-container {
        width: 100%;
        margin: 0 auto;

        .project-cards {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            gap: 1.5rem;

            .card-wrap {
                display: flex;
                flex-direction: row;
                width: 100%;
                max-width: 19ch;

                .card {
                    box-shadow: 0 1px 10px 2px rgb(232, 232, 232);
                    padding: 0.55rem 0.6rem 0.4rem 0.75rem;
                    border-radius: 2px;
                    transition: all 0.9s ease;
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    &:not([class*="light-mode"]) {
                        box-shadow: 0 1px 10px 2px rgba(59, 59, 59, 0.9);
                        background: rgb(36, 36, 36);
                        transition: all 0.9s ease;
                    }

                    .card-space-filler {
                        flex-grow: 1;
                    }

                    .title {
                        opacity: 0;
                        font-family: "Roboto", sans-serif;
                        font-size: clamp(0.475em, 7vw, 0.755em);
                        margin-bottom: 0.725rem;
                        transition: all 0.9s ease;
                    }

                    .body {
                        opacity: 0;
                        font-size: clamp(0.375em, 5vw, 0.615em);
                        transition: all 0.9s ease;
                    }

                    .footer {
                        width: 100%;
                        font-family: "Alegreya Sans", sans-serif;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: clamp(0.315em, 5vw, 0.465em);
                        margin-top: 1rem;

                        p {
                            background: none;
                            color: rgb(117, 170, 135);
                            transition: all 0.9s ease;

                            &:not([class*="light-mode"]) {
                                color: rgb(157, 252, 255);
                            }
                        }

                        svg {
                            width: 1.1rem;
                            height: 1.1rem;
                        }
                    }
                }
            }
        }
    }
}

/* Media Queries */
@media only screen and (max-width: 400px) {
    .project-content {
        h2 {
            font-size: clamp(1.175em, 13vw, 1.515em);
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .project-content {
        h2 {
            margin-bottom: 3rem;
        }

        .card-container {
            .project-cards {
                gap: 2rem;

                .card-wrap {
                    max-width: 23ch;

                    .card {
                        .title {
                            font-size: clamp(0.775em, 7vw, 0.975em);
                            margin-bottom: 1.225rem;
                        }

                        .body {
                            font-size: clamp(0.525em, 5vw, 0.715em);
                        }

                        .footer {
                            font-size: clamp(0.315em, 5vw, 0.575em);
                            margin-top: 2rem;
                        }
                    }
                }
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .project-content {
        .card-container {
            .project-cards {
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
        }
    }
}

/* Landscape mode */
@media (orientation: landscape) {
    .project-content {
        .card-container {
            .project-cards {
                flex-direction: row;
                align-items: stretch;
                gap: 2rem;
            }
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .project-content {
        .card-container {
            .project-cards {
                flex-direction: row;
                align-items: stretch;
                gap: 3rem;

                .card-wrap {
                    max-width: 25ch;

                    .card {
                        .title {
                            font-size: clamp(0.975em, 7vw, 1.115em);
                            margin-bottom: 1.225rem;
                        }

                        .body {
                            font-size: clamp(0.725em, 5vw, 0.785em);
                        }

                        .footer {
                            font-size: clamp(0.315em, 5vw, 0.675em);

                            svg {
                                width: 1.4rem;
                                height: 1.4rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
