.sun-svg {
    cursor: pointer;
    transition: transform 250ms;
    width: 2.325em;
    height: 2.325em;

    line,
    circle {
        transition: 0.7s ease;
    }

    &:hover {
        transform: scale(1.1);

        circle {
            transition: none;
            fill: rgb(104, 215, 231);
        }

        line {
            transition: none;
            stroke: rgb(104, 215, 231);
        }

        @media (max-width: 992px) {
            transform: none;
        }
    }
}
