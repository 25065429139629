.github-full-svg {
    cursor: pointer;
    transition: transform 250ms;
    width: 1.2rem;
    height: 1.2rem;
    stroke: rgb(117, 170, 135);

    path {
        transition: 0.9s ease;
        fill: none;
    }

    &:hover {
        transform: translateY(-5px);
    }

    &:not([class*="light-mode"]) {
        stroke: rgb(157, 252, 255);

        path {
            transition: 0.9s ease;
            fill: none;
        }
    }
}
