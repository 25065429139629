.nav-menu-container {
    font-family: "Alegreya Sans", sans-serif;
    width: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    transition: 0.6s ease;

    .nav-menu-bar {
        position: fixed;
        background-color: rgba(221, 221, 221, 0.3);
        backdrop-filter: blur(10px);
        width: 100%;
        height: 60px;
        z-index: 10;
        top: 0;

        &:not([class*="light-mode"]) {
            background-color: rgba(36, 36, 36, 0.3);
        }
    }

    &:not([class*="light-mode"]) {
        transition: 0.6s ease;
        background: rgba(0, 0, 0, 0.9);
    }

    .nav-menu-items-container {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    #hamburger-menu {
        width: 33px;
        height: 33px;
    }

    .logo {
        margin-top: auto;
    }

    .nav-menu-icon {
        margin-top: 3rem;
        margin-bottom: auto;
    }

    .nav-menu-items {
        position: relative;
        width: 100%;
        text-align: center;

        a {
            padding: 1rem;
            text-decoration: none;
            display: block;
            transition: 0.9s ease;
            font-size: clamp(1.75em, 10vw, 2.225em);
            letter-spacing: 0.115rem;

            &:not(:first-child) {
                padding-top: 0.6rem;
            }

            &.light-hover {
                &:focus,
                &:hover {
                    transition: none;
                    color: rgb(117, 170, 135);
                }
            }

            &.dark-hover {
                &:focus,
                &:hover {
                    transition: none;
                    color: rgb(104, 215, 231);
                }
            }
        }
    }
}

.nav-menu {
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
    padding: 0.95rem 0.65rem 0.3rem 0;

    line {
        stroke: rgb(0, 0, 0);
    }

    &:hover {
        filter: brightness(200%);
    }

    &:not([class*="light-mode"]) {
        line {
            stroke: white;
        }
    }
}

.show-nav {
    height: 100%;

    #top-line {
        animation: 400ms 0s 1 forwards slide-left;
        -webkit-animation: 400ms 0s 1 initial slide-left;
        -moz-animation: 400ms 0s 1 initial slide-left;
    }

    #middle-line {
        animation: 400ms 100ms 1 forwards slide-left;
        -webkit-animation: 400ms 100ms 1 initial slide-left;
        -moz-animation: 400ms 100ms 1 initial slide-left;
    }

    #bottom-line {
        animation: 400ms 175ms 1 forwards slide-left;
        -webkit-animation: 400ms 175ms 1 initial slide-left;
        -moz-animation: 400ms 175ms 1 initial slide-left;
    }

    #x-pos,
    #x-neg {
        transition-delay: 0.4s;
        -webkit-transition-delay: 0.4s;
        -moz-transition-delay: 0.4s;
        -ms-transition-delay: 0.4s;
        opacity: 1;
    }
}

.hide-nav {
    height: 0;
}

/* Media Queries */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .nav-menu-container {
        .nav-menu-items {
            a {
                padding: 2rem;

                &:not(:first-child) {
                    padding-top: 1.2rem;
                }
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
    .nav-menu-container {
        display: none;
    }
}

/* For landscape modes up to 1200px */
@media screen and (max-width: 1200px) and (orientation: landscape) {
    .nav-menu-container {
        display: flex;
    }
    .nav-menu-container {
        .nav-menu-items {
            a {
                padding: 1rem;

                &:not(:first-child) {
                    padding-top: 0.8rem;
                }
            }
        }
    }
}

/* Animations */
@keyframes slide-left {
    from {
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -ms-transform: translateX(0px);
    }

    75% {
        transform: translateX(-20px);
        -webkit-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
    }

    to {
        opacity: 0;
        transform: translateX(-40px);
        -webkit-transform: translateX(-40px);
        -ms-transform: translateX(-40px);
    }
}
