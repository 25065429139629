$transition: all 0.9s ease;

.home-container {
    p {
        transition: $transition;
        -moz-transition: $transition;
        -webkit-transition: $transition;
        -ms-transition: $transition;
        animation: 1200ms 0ms 1 ease-out home-fade-in-from-top;
        -webkit-animation: 1200ms 0ms 1 ease-out home-fade-in-from-top;
        -moz-animation: 1200ms 0ms 1 ease-out home-fade-in-from-top;
    }

    .image-container {
        width: 10em;
        height: 10em;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        animation: 1200ms 0ms 1 ease-out home-fade-in-from-top;
        -webkit-animation: 1200ms 0ms 1 ease-out home-fade-in-from-top;
        -moz-animation: 1200ms 0ms 1 ease-out home-fade-in-from-top;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center;
        }
    }

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .item1,
    .item2,
    .item3,
    .item4 {
        font-size: clamp(1.125em, 3.525vw, 1.575em);
        letter-spacing: 0.05rem;
        padding: 0 1rem;
    }

    .item1 {
        margin-top: 2rem;
    }

    .item3 {
        font-size: clamp(0.575em, 1.525vw, 0.825em);
        line-height: 1.25rem;
    }

    .item4 {
        font-size: clamp(0.675em, 3.525vw, 1.325em);
        line-height: 1.575rem;
        margin-top: 1.5rem;
    }
}

/* Media Queries */
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .home-container {
        .image-container {
            width: 15em;
            height: 15em;
        }

        .item1,
        .item2,
        .item3,
        .item4 {
            font-size: clamp(1.075em, 7vw, 1.675em);
        }

        .item3 {
            font-size: clamp(0.75em, 3vw, 1.18em);
        }

        .item4 {
            font-size: clamp(1.075em, 7vw, 1.175em);
            line-height: 2rem;
            margin-top: 3rem;
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .home-container {
        .image-container {
            width: 19em;
            height: 19em;
        }

        .item1,
        .item2,
        .item3,
        .item4 {
            font-size: clamp(1.075em, 7vw, 1.975em);
            padding: 0 3rem;
        }

        .item3 {
            font-size: clamp(0.75em, 3vw, 0.875em);
        }

        .item4 {
            font-size: clamp(1.075em, 7vw, 1.215em);
            line-height: 2.2rem;
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .home-container {
        padding-top: 0;

        p,
        .image-container {
            animation: 1200ms 0ms 1 ease-out home-fade-in-from-left;
            -webkit-animation: 1200ms 0ms 1 ease-out home-fade-in-from-left;
            -moz-animation: 1200ms 0ms 1 ease-out home-fade-in-from-left;
        }

        .image-container {
            width: 17em;
            height: 17em;
        }

        .item1,
        .item2,
        .item3,
        .item4 {
            font-size: clamp(1.575em, 7vw, 1.875em);
        }

        .item3 {
            font-size: clamp(0.875em, 7vw, 0.975em);
        }

        .item4 {
            font-size: clamp(0.875em, 7vw, 1.275em);
            max-width: 35ch;
            line-height: 2.2rem;
        }
    }
}

/** Landscape media query **/
@media only screen and (orientation: landscape) {
    .home-container {
        margin-left: 0;

        p,
        .image-container {
            animation: 1200ms 0ms 1 ease-out home-fade-in-from-top;
            -webkit-animation: 1200ms 0ms 1 ease-out home-fade-in-from-top;
            -moz-animation: 1200ms 0ms 1 ease-out home-fade-in-from-top;
        }

        .image-container {
            width: 16em;
            height: 16em;
        }

        display: grid;
        text-align: start;
        align-content: center;
        column-gap: 3.725rem;
        grid-template-columns: repeat(9, 1fr);
        grid-template-rows: repeat(2, 1fr) minmax(20px, 70px) minmax(60px, 90px) minmax(30px, 60px) repeat(3, 1fr);
        grid-template-areas:
            ". . . . . . . . ."
            ". pic pic . . . . . ."
            ". pic pic item1 item1 item1 item1 item1 ."
            ". pic pic item2 item2 item2 item2 item2 ."
            ". pic pic item3 item3 item3 item3 item3 ."
            ". pic pic item4 item4 item4 item4 item4 item4"
            ". pic pic . . . . . ."
            ". . . . . . . . .";

        .pic {
            grid-area: pic;
            justify-self: end;
            align-self: center;
        }

        .item1 {
            grid-area: item1;
            align-self: flex-end;
            padding: 0;
            margin: 0;
            font-size: clamp(0.675em, 10vw, 1.275em);
        }

        .item2 {
            grid-area: item2;
            font-size: clamp(1.675em, 7vw, 2.625em);
            align-self: flex-end;
            padding: 0;
            margin: 0;
        }

        .item3 {
            grid-area: item3;
            font-size: clamp(0.875em, 7vw, 0.975em);
            align-self: flex-start;
            padding: 0;
            margin: 0;
        }

        .item4 {
            grid-area: item4;
            align-self: flex-start;
            max-width: none;
            padding: 0;
            margin: 0;
            font-size: clamp(0.875em, 7vw, 0.825em);
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .home-container {
        margin-left: 0;

        p,
        .image-container {
            animation: 1200ms 0ms 1 ease-out home-fade-in-from-left;
            -webkit-animation: 1200ms 0ms 1 ease-out home-fade-in-from-left;
            -moz-animation: 1200ms 0ms 1 ease-out home-fade-in-from-left;
        }

        .image-container {
            width: 18em;
            height: 18em;
        }

        display: grid;
        text-align: start;
        align-items: center;
        justify-content: center;
        column-gap: 3.725rem;
        grid-template-columns: repeat(9, 1fr);
        grid-template-rows: repeat(2, 1fr) minmax(40px, 1fr) minmax(60px, 100px) minmax(30px, 60px) repeat(3, 1fr);
        grid-template-areas:
            ". . . . . . . . ."
            ". . . . . . . . ."
            ". pic pic pic item1 item1 item1 item1 ."
            ". pic pic pic item2 item2 item2 item2 item2"
            ". pic pic pic item3 item3 item3 item3 ."
            ". pic pic pic item4 item4 item4 item4 item4"
            ". . . . . . . . ."
            ". . . . . . . . .";

        .pic {
            grid-area: pic;
            justify-self: end;
            align-self: center;
        }

        .item1 {
            grid-area: item1;
            align-self: flex-end;
            padding: 0;
            margin: 0;
            font-size: clamp(0.875em, 10vw, 1.475em);
        }

        .item2 {
            grid-area: item2;
            font-size: clamp(1.375em, 7vw, 2.725em);
            align-self: flex-end;
            padding: 0;
            margin: 0;
        }

        .item3 {
            grid-area: item3;
            font-size: clamp(0.775em, 7vw, 0.995em);
            align-self: flex-start;
            padding: 0;
            margin: 0;
        }

        .item4 {
            grid-area: item4;
            align-self: flex-start;
            max-width: none;
            padding: 0;
            padding-right: 1rem;
            margin: 0;
            font-size: clamp(0.875em, 2vw, 0.925em);
            max-width: 32ch;
        }
    }
}

/* Huge Devices */
@media only screen and (min-width: 1500px) {
    .home-container {
        margin-left: 0;

        .image-container {
            width: 20em;
            height: 20em;
        }

        display: grid;
        text-align: start;
        align-items: center;
        justify-content: center;
        column-gap: 3.725rem;
        grid-template-columns: repeat(9, 1fr);
        grid-template-rows: repeat(2, 1fr) minmax(40px, 150px) minmax(60px, 110px) minmax(30px, 60px) repeat(3, 1fr);
        grid-template-areas:
            ". pic pic pic . . . . ."
            ". pic pic pic . . . . ."
            ". pic pic pic item1 item1 item1 item1 ."
            ". pic pic pic item2 item2 item2 item2 ."
            ". pic pic pic item3 item3 item3 item3 ."
            ". pic pic pic item4 item4 item4 item4 ."
            ". pic pic pic . . . . ."
            ". pic pic pic . . . . .";

        .item1 {
            grid-area: item1;
            align-self: flex-end;
            font-size: clamp(1.375em, 10vw, 1.715em);
        }

        .item2 {
            grid-area: item2;
            font-size: clamp(1.875em, 10vw, 3.325em);
            align-self: flex-end;
        }

        .item3 {
            grid-area: item3;
            font-size: clamp(0.775em, 7vw, 0.915em);
            align-self: flex-start;
        }

        .item4 {
            grid-area: item4;
            align-self: flex-start;
            max-width: none;
            padding: 0;
            font-size: clamp(0.875em, 2vw, 1.225em);
            max-width: 90%;
        }
    }
}

/* Animations */
@keyframes home-fade-in-from-left {
    from {
        opacity: 0;
        transform: translateX(-200px);
        -webkit-transform: translateX(-200px);
        -ms-transform: translateX(-200px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -ms-transform: translateX(0px);
    }
}

@keyframes home-fade-in-from-top {
    from {
        opacity: 0;
        transform: translateY(-200px);
        -webkit-transform: translateY(-200px);
        -ms-transform: translateY(-200px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
    }
}
